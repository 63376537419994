import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useOrgId, useOrgName } from 'src/apollo/local-state';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import Button from 'src/components/button/button';
import Icon from 'src/components/icon/icon';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import { getStripeSetUpLink } from 'src/components/saas-memberships/utils';
import Spinner from 'src/components/spinner/spinner';
import StatusLabel from 'src/components/status-label/status-label';
import { BodyLarge } from 'src/components/typography/typography';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { getEnvConfig } from 'src/config/config';
import { fetchStripeAccountDetails, fetchVenue, StripeAccountDetails, VenueDetails } from 'src/utils/classic-api';
import ROUTES from 'src/utils/routes';

import * as styles from './stripe-setup.module.less';

export const StripeSetup: React.FC = () => {
  const { t } = useTranslation();

  const [stripeUrlSegment, setStripeUrlSegment] = useState<string | undefined>();
  const [stripeAccountId, setStripeAccountId] = useState<string | undefined>();
  const [stripeAccountDetails, setStripeAccountDetails] = useState<StripeAccountDetails | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const orgId = useOrgId();
  const orgName = useOrgName();

  useEffect(() => {
    (async () => {
      setLoading(true);
      setError(undefined);

      let venue: VenueDetails | undefined;

      try {
        venue = await fetchVenue(orgId);
      } catch (error) {
        console.warn('Failed to fetch venue data: ', error && (error as Error)?.message);
        setError('failed to fetch venue data');
      }

      try {
        const urlSegment = venue?.UrlSegment;
        setStripeUrlSegment(urlSegment);
        const accountId = venue?.StripeAccounts.find((account) => account.Tag === 'default')?.UserID;
        setStripeAccountId(accountId);

        if (urlSegment && accountId) {
          const accountDetails = await fetchStripeAccountDetails(urlSegment, accountId);
          setStripeAccountDetails(accountDetails);
        }
      } catch (error) {
        console.warn('Failed to fetch stripe account details: ', error && (error as Error)?.message);
        setError('failed to fetch stripe account details');
      }
      setLoading(false);
    })();
  }, [orgId, orgName]);

  const onChangeAccountButtonClick = useCallback(() => {
    if (stripeAccountId) navigate(`https://dashboard.stripe.com/applications/users/${stripeAccountId}`);
  }, [stripeAccountId]);

  useEffect(() => {
    if (error) toast.error(t(error));
  }, [error, t]);

  return (
    <PageMaxWidth>
      <Breadcrumbs
        paths={[
          {
            name: t('membership settings'),
            to: `${ROUTES.SAAS_MEMBERSHIP_SETTINGS}`,
            highlight: true,
          },
          {
            name: t('stripe setup'),
            active: true,
          },
        ]}
      />
      <Grid className={styles.title} container justifyContent="space-between" alignItems="center">
        <PageHeader title={t('stripe setup title')} />
      </Grid>
      <Panel
        title={t('connected account')}
        headerEndContent={
          stripeAccountDetails && (
            <Button onClick={onChangeAccountButtonClick} size={'sm'} spacing={{ margins: { sm: 'left' } }}>
              {t('change')}
            </Button>
          )
        }
      >
        <div className={styles.connectedAccount}>
          <Icon name={'xl-payment'} />
          <div className={styles.connectedAccountContent}>
            {loading && <Spinner />}
            {!loading &&
              (stripeAccountDetails ? (
                <ConnectedAccountInfo account={stripeAccountDetails} />
              ) : (
                <SetUpAccount urlSegment={stripeUrlSegment} />
              ))}
          </div>
        </div>
      </Panel>
    </PageMaxWidth>
  );
};

interface ConnectedAccountProps {
  account: StripeAccountDetails;
}

const ConnectedAccountInfo: React.FC<ConnectedAccountProps> = ({ account = {} }) => {
  const { t } = useTranslation();
  return (
    <>
      <AccountInfoItem label={t('account owner')} value={account.Owner} />
      <AccountInfoItem label={t('email')} value={account.Email} />
      <AccountInfoItem label={t('date created')} value={account.CreationDate} />
      <AccountInfoItem label={t('status')} value={account.Status && <AccountStatus status={account.Status} />} />
    </>
  );
};

interface AccountInfoItemProps {
  label: ReactNode;
  value: ReactNode | null;
}

const AccountInfoItem: React.FC<AccountInfoItemProps> = ({ label, value }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.accountInfo}>
      <span className={styles.accountInfoLabel}>{label}:</span> {value || t('n/a')}
    </div>
  );
};

interface AccountStatusProps {
  status: string;
}

const AccountStatus: React.FC<AccountStatusProps> = ({ status }) => {
  const { t } = useTranslation();
  const approved = status === 'OK';
  return (
    <StatusLabel variety={approved ? 'success' : 'error'}>{approved ? t('approved') : t('restricted')}</StatusLabel>
  );
};

interface SetUpAccountProps {
  urlSegment: string | undefined;
}

const SetUpAccount: React.FC<SetUpAccountProps> = ({ urlSegment }) => {
  const { t } = useTranslation();
  const orgName = useOrgName();

  const onSetUpAccountButtonClick = useCallback(() => {
    const stripeBaseUrl = getEnvConfig().CLUBSPARK_CLASSIC_URL;
    const returnAddress = window.location.href;

    if (stripeBaseUrl && urlSegment && returnAddress)
      navigate(getStripeSetUpLink(stripeBaseUrl, urlSegment, returnAddress));
  }, [urlSegment]);

  return (
    <>
      <BodyLarge spacing={{ margins: { sm: 'vertical' } }}>
        {t('no connected account for payments', { facility: orgName })}
      </BodyLarge>
      <div className={styles.setupButtonContainer}>
        <Button spacing={{ margins: { sm: 'right' } }} onClick={onSetUpAccountButtonClick}>
          {t('set up account')}
        </Button>
      </div>
    </>
  );
};
