import React from 'react';

import { ToastContainer } from 'react-toastify';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import { StripeSetup } from 'src/components/saas-memberships/stripe-setup/stripe-setup';
import SEO from 'src/components/seo';

const StripeSetupPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Saas Membership Stripe Setup" />
      <PrivateRoute>
        <ToastContainer hideProgressBar pauseOnHover theme="colored" position="top-right" />
        <StripeSetup />
      </PrivateRoute>
    </Layout>
  );
};

export default StripeSetupPage;
