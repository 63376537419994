export function createArrayUpdater<T, U extends Array<T>>(index: number, value: T) {
  return (prev: U) => {
    const arr = [...prev];
    arr[index] = value;
    return arr as U;
  };
}

export function areArraysEqual<T extends Array<any>, U extends Array<any>>(a: T, b: U) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function createArrayComparer<T extends Array<any>, U extends Array<any>>(compareTo: T) {
  return (arr: U) => areArraysEqual(arr, compareTo);
}

export const getInitialsFromFullName = (fullName?: string | null) =>
  fullName
    ? fullName
        ?.split(' ')
        ?.map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        ?.join('')
    : '';

export const getPersonFullName = <T>(
  data?: (T & { standardGivenName: string | null; standardFamilyName: string | null }) | null,
): string => `${data?.standardGivenName || ''} ${data?.standardFamilyName || ''}`;
export const isEmptyObject = (obj: Record<string, any>) => !Object.values(obj).some((v) => typeof v !== 'undefined');

/**
 * Given an array of objects, returns a new array sorted by date.
 * @param arr An array of objects
 * @param sortOptions An object defining the sorting order and the name of the field that should be used for sorting
 * @returns
 */
export const sortByDate = <TField extends string, TObj extends Record<TField, string>>(
  arr: TObj[],
  sortOptions: { fieldName: TField; order: 'ASC' | 'DESC' },
) => {
  const { fieldName, order } = sortOptions;
  return [...arr].sort((a, z) => {
    const dateA = new Date(a[fieldName]).getTime();
    const dateZ = new Date(z[fieldName]).getTime();
    return order === 'ASC' ? dateA - dateZ : dateZ - dateA;
  });
};

export const sortByName = <TField extends string, TObj extends Record<TField, string>>(
  arr: TObj[],
  sortOptions: { fieldName: TField; order: 'A-Z' | 'Z-A' },
) => {
  const { fieldName, order } = sortOptions;
  return [...arr].sort((a, z) => {
    const nameA = a[fieldName];
    const nameZ = z[fieldName];
    return order === 'A-Z' ? nameA.localeCompare(nameZ) : nameZ.localeCompare(nameA);
  });
};
